import { useUtm } from "~/composables/Utm";
import { md5 } from "js-md5";

export const useContact = () => {

  const utm = useUtm();
  const backend = useBackend();
  const route = useRoute();
  const salesManago = useSalesManago();

  const { analyticsLayer } = useAnalytics();

  const submit = (formData) => {
    return new Promise(async resolve => {

      const data = {
        mail: formData.value.mail,
        name: formData.value.name,
        phone: formData.value.phone,
        agr1: formData.value.agr_p ? 1 : 0,
        agr2: formData.value.agr_m ? 1 : 0,
        url: utm.getUrlWithUtmParameters(),
        brand: formData.value.brand,
        model: formData.value.model,
        body: formData.value.body,
        options: formData.value.options
      }

      if(formData.value?.source) {
        data.source = formData.value.source
      }

      const result = await backend.sendContactForm(data)

      if(result.data.error || result.status !== 200) {
        resolve(false);
        return;
      }

      // salesmanago part
      let contactId = result.data.data.data.data?.contact_id;

      salesManago.setCookie(contactId);
      await salesManago.sendEvent('OFFER', formData.value.product_id);

// end of salesmanago

      let transactionId = result.data.data.data.data.id;

      const formTypes = {
        model: 'samochody',
        brand: 'samochody',
        cars: 'wybierz',
        offer: 'oferta',
        decide: 'pobierz',
        index: 'home',
        search: 'wyszukaj',
        "static-offer-consumer": 'konsument',
        "static-offer-entrepreneur":"przedsiebiorca",
        "static-offer": "offer",
      }

      const formType = formData.value.formType || formTypes[route.name] || route.name;

      analyticsLayer({
        'event': 'generate_lead',
        'form_type' : formType,
        'transactionId' : transactionId,
        'email' : data.mail,
        'md5_email_hash': data?.mail ? md5(data.mail.toLowerCase().trim()) : "",
        'phone' : data.phone,
        'brand': data.brand,
        'model': data.model,
        'body': data.body,
        'product_id': formData.value.product_id

      });

      resolve(true);
    })
  }

  return {
    submit
  }

}
