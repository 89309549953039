export const useSalesManago = () => {

  const contactId = useCookie('smclient', {maxAge: 10 * 60 * 60 * 24 * 365, httpOnly: false, secure: false})
  const console = useConsole();

  const setCookie = (clientContactId) => {
    if(contactId) {
      contactId.value = clientContactId;
    }
  }

  const sendEvent = async (eventName, productId) => {

    console.log('🛒',`SM Received ${eventName} ${productId}`);

    if(!contactId.value) return false;

    const data  = await $fetch('/srv/salesmanago', {
      method: 'POST',
      body: {
        contactId: contactId.value,
        event: eventName,
        productId: productId,
      }
    })

    console.log('🛒', 'SM Fetched', data);
    return data;
  }

  return {
    sendEvent,
    setCookie
  }
}
